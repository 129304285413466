import * as React from "react";
import {
  Layout,
  Splash,
  PortfolioGrid,
  ContentGrid,
  ServicesGrid,
  Cta,
} from "components";
import { graphql } from "gatsby";

const transformTeamArr = (arr) => {
  const newArr = [];
  arr.forEach((elem) => {
    newArr.push(elem, {});
  });
  return newArr;
};

const Index = ({ data }) => {
  const technologies_content = data.allTechnologiesDataJson.edges;
  const brands_content = data.allBrandsDataJson.edges;
  const services_content = data.allServicesDataJson.edges;
  const team_content = data.allTeamDataJson.edges;
  const portfolio_content = data.allContentfulPortfolio.edges;

  const transformed_team_content = transformTeamArr(team_content);

  return (
    <Layout>
      <Splash
        sectionSubtitle="About"
        sectionTitle="CTO/CPO | Forbes 30U30 | Helping Startups Build, Scale & Innovate"
        sectionText={`Hi, I'm Will. I’ve launched over a dozen commercially successful products, led tech & product as CTO/CPO at multiple venture-backed startups, and built teams and products to generate $8M+ in ARR. Some of what I've accomplished over the years:

        🚀 Shipped MVPs in weeks by cutting scope to <5%
        🔥 Hit #7 on Product Hunt as solo software engineer
        🤖 Built internal AI tools saving 20+ hours/week in ops
        💼 Delivered enterprise software used by Fortune 500s
        🏆 Forbes 30 Under 30 for work in tech & startups

Now, I help founders and teams move faster — with the right mix of product strategy, technical execution, and AI innovation.
          `}
        showBtn
        type="home"
      />
      <PortfolioGrid portfolio={portfolio_content} />
      <ContentGrid
        sectionSubtitle="Partnerships"
        sectionTitle="Brands that I work with"
        content={brands_content}
      />
      <ServicesGrid content={services_content} />
      <ContentGrid
        sectionSubtitle="Technologies"
        sectionTitle="The tech stack that I rely on"
        content={technologies_content}
      />
      <ContentGrid
        sectionSubtitle="Team"
        sectionTitle="People I work with"
        content={transformed_team_content}
        dark
      />
      <Cta />
    </Layout>
  );
};

export const query = graphql`
  query {
    allTechnologiesDataJson {
      edges {
        node {
          label
          img {
            childImageSharp {
              gatsbyImageData(width: 60, placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
    allBrandsDataJson {
      edges {
        node {
          label
          img {
            childImageSharp {
              gatsbyImageData(width: 100, placeholder: BLURRED)
            }
          }
        }
      }
    }
    allServicesDataJson {
      edges {
        node {
          label
          value
          description
          img {
            childImageSharp {
              gatsbyImageData(width: 400, placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
    allTeamDataJson {
      edges {
        node {
          img {
            childImageSharp {
              gatsbyImageData(width: 300, placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
    allContentfulPortfolio(sort: { order: ASC, fields: order }, limit: 3) {
      edges {
        node {
          ...PortfolioFragment
        }
      }
    }
  }
`;

export default Index;
